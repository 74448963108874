<template>
  <v-col
    class="pa-0"
  >
    <data-table
      ref="table"
      :actions="actions"
    />

    <line-item-history-modal
      v-if="isLineItemHistoryModalVisible"
      v-model="isLineItemHistoryModalVisible"
      :line-item-record="currentRecord"
    />

    <creatives-report-modal
      v-if="isCreativesReportModalVisible"
      v-model="isCreativesReportModalVisible"
      :line-item-record="currentRecord"
    />

    <tearsheet-modal
      v-if="isCreativeModalVisible"
      v-model="isCreativeModalVisible"
      :line-item-record="currentRecord"
    />
  </v-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import mixin from '../components/mixin'

const { mapState } = createNamespacedHelpers('delivery')
const { mapGetters: mapMessagesGetters } = createNamespacedHelpers('messages')
const { mapGetters, mapState: mapToolsState } = createNamespacedHelpers('tools')

export default {
  name: 'Pacing',
  components: {
    creativesReportModal: () => import('../components/modals/CreativesReportModal'),
    lineItemHistoryModal: () => import('../components/modals/LineItemHistoryModal'),
    tearsheetModal: () => import('../components/modals/TearsheetModal'),
  },
  mixins: [mixin],
  data() {
    return {
      actions: [
        {
          event: 'delivery/openTabToLineItem',
          name: 'Go to Line Item',
        },
        {
          event: 'delivery/openTabToOrder',
          name: 'Go to Order',
        },
        {
          event: 'delivery/showLineItemHistoryModal',
          name: '10-Day History Report',
        },
        {
          event: 'delivery/showCreativesReportModal',
          name: 'Creatives Report',
        },
        {
          event: 'delivery/showTearsheetModal',
          name: 'Preview & Tearsheet',
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'currentSummaries',
      'reportKey',
      'showCurrentSavedFilters',
    ]),
    ...mapMessagesGetters(['currentMessage']),
    ...mapToolsState({
      reportId: (state) => state.reportId,
      reportName: (state) => state.reportName,
      summaries: (state) => state.summaries,
    }),
    ...mapState({
      currentRecord: (state) => state.currentRecord,
      isLineItemHistoryModalVisible: (state) => state.isLineItemHistoryModalVisible,
      isCreativesReportModalVisible: (state) => state.isCreativesReportModalVisible,
      isCreativeModalVisible: (state) => state.isCreativeModalVisible,
      publisher: (state) => state.publisher,
      subId: (state) => state.subId,
    }),
  },
}
</script>

<style lang="scss" scoped>
@import "~src/styles/variables";

::v-deep.filter-link {
  margin-top: 8px;
}

::v-deep.filter-content {
  margin-top: 16px;
}

::v-deep.filter-label {
  padding-top: 20px;
}

.delivery-table-filter {
  .first-row {
    display: flex;

    .v-text-field {
      margin: 0 24px;
      padding-top: 0;
      font-size: 12px;
    }
  }

  .second-row {
    display: flex;
    ::v-deep.v-text-field {
      margin: 0 24px;
      padding-top: 0;
      font-size: 12px;
    }

    .osi-selector {
      max-width: 350px;
    }

    .filter-controls-spacer {
      min-height: 52px;
    }
  }

  ::v-deep.v-date-range {
    .v-text-field {
      min-width: 250px !important;
    }
  }
}
</style>
